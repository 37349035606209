.aboutGov {
  /* background: linear-gradient(90deg, rgba(0, 0, 0, .85), rgba(0, 0, 0, .85), rgba(0, 0, 0, .85)), url(/public/assets/img/pexels-monstera.jpg) no-repeat; */
  background-size: 100% 100%;
  background-attachment: fixed;
  color: black;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .025em;
  line-height: 1.8;
  margin-bottom: 0;
  min-height: 100vh;
  min-height: calc(100vh - var(--vh-offset, 0px));
  overflow-x: hidden;
  width: 100%;
  padding-bottom: 3rem;
}

.aboutGovContainer {
  color: black;
  display: block;
  /* background: rgba(255, 255, 255, 0.11); */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
  /* backdrop-filter: blur(2.6px); */
  /* -webkit-backdrop-filter: blur(2.6px); */
  /* border: 1px solid rgba(255, 255, 255, 0.3); */
  border: 2.5px solid rgb(9, 16, 149);
}

.dist-governer {
  display: flex;
  justify-content: center;
  color: black;
  gap: 7rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
}

.dist-governer div {
  margin-top: 3rem;
}