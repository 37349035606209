.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 4rem 1rem;
  overflow: hidden;
  gap: 5rem 2rem;
}
.team-h {
  font-size: 42px;
  font-family: sans-serif;
  font-weight: bolder;
  padding: 2rem 0;
  text-align: center;
}
.profile-card {
  position: relative;
  width: 350px;
  height: 360px;
  background: #fff;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  border-radius: 10px;
}
.profile-card:hover {
}
.profile-card .img {
  position: relative;
  transition: 0.6s;
  z-index: 99;
}
.profile-card:hover .img {
}
.img img {
  transform: translateY(-60px);
  border-radius: 10px;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  width: 100%;
  height: 260px;
}
.profile-card:hover img {
}
.caption {
  text-align: center;
  transform: translateY(-80px);
  opacity: 0;
  transition: 0.6s;
  opacity: 1;
  margin-top: 2rem;
}
.profile-card:hover .caption {
}
.caption h3 {
  font-size: 21px;
  margin-bottom: 0.3rem;
  color: rgba(0, 0, 0, 0.831);
}

.caption h4 {
  margin: 0;
  color: rgba(63, 59, 59, 0.8);
}
.caption p {
  font-size: 16px;
  color: #0c52a1;
  margin: 2px 0 9px 0;
}
.caption .social-links a {
  color: #333;
  margin-right: 8px;
  font-size: 21px;
  transition: 0.6s;
}
.social-links a:hover {
  color: #0c52a1;
}

@media only screen and (max-width: 1150px) {
  .profile-card {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .img img {
    width: 100%;
    height: 230px;
  }

  .profile-card {
    height: 340px;
  }
}
