.counters {
  width: 100%;
  padding: 3rem 0;
  background: #112E57;
  background-size: cover;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counterGrid {
  display: flex;
  justify-content: space-evenly;
}

.counters > div {
  padding: 2rem;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.495);
  width: 95%;
  box-shadow: 0 4px 20px #76767689;
}

.counters > div > div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.count, .count h3 {
  font-weight: 700;
}

.count {
  width: 13rem;
  height: 13rem;
  border-radius: 50%;
  background: rgba(113, 113, 113, 0.386);
  border: 0.6rem solid #dda42a;
  margin: 1rem auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  /* opacity: 0; */
  /* animation: fadeIn 0.75s linear forwards; */
  /* animation-delay: 0.5s; */
}

.count > div {
  text-align: center;
}

.event-icon > img {
  margin: auto;
}

.count > div > .event-icon {
  font-size: 100%;
}

.count > div > .event-count {
  font-size: 30%;
}