.imgSlider {
  position: relative;
  padding-top: 75%;
}

.imgSlider > .hOQiSy {
  width: 100% !important;
}

.imgSlider > article {
  width: 100% !important;
  position: absolute;
  top: 0;
}

.imgSlider > svg {
  color: #fff !important;
}

.imgSlider > article > .epKbGN > .hCJOJS {
  line-height: 0 !important;
  background: #8e8e8e91 !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(6px) !important;
  -webkit-backdrop-filter: blur(6px) !important;
}

.hCJOJS > svg {
  color: #fff !important;
}

.imgSlider > article > div:nth-child(2) {
  top: 50% !important;
  z-index: 999;
}

.imgSlide {
  position: relative;
  padding-top: 75%;
}

.imgSlide > img {
  object-fit: fill;
  position: absolute;
  top: 0;
  height:100vh !important;
}

.imgSlide:before {
  background: #000;
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 84%) 0,
    rgb(0 0 0 / 74%) 10%,
    rgba(0, 0, 0, 0) 63%
  );
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: "";
  z-index: 9;
}

.slideOverlay {
  position: relative;
}

.slideOverlay:before {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.sc-dmyDGi,
.gtrAwG,
.sc-eDLJxc,
.gQPiQM {
  display: none !important;
  color: transparent !important;
}

@media only screen and (max-width: 768px) {
  .imgSlider > article > div:nth-child(2) {
    top: 35% !important;
  }
}

@media only screen and (max-width: 480px) {
  .imgSlider > article > div:nth-child(2) {
    top: 30% !important;
  }
  .faTUqF {
    width: 1.5rem !important;
    height: 1.5rem !important;
    font-weight: bold !important;
    line-height: 0.6rem !important;
    
  }
}

@media only screen and (max-width: 280px) {
  .imgSlider > article > div:nth-child(2) {
    top: 30% !important;
  }
}

/* Header Styling */

.head-dist-h {
  text-align: center;
  color: #000000;
  background-color: #ffffff00;
  position: absolute;
  width: 100%;
  z-index: 998;
  padding: 1rem;
}

.head-lions-i,
.head-dist-num {
  color: #fff;
  font-weight: 700;
  text-align: left;
  text-shadow: 0.1em 0.1em 0 hsl(0, 0%, 0%);
}

.head-lions-i {
  font-size: 2rem;
  font-weight: 600;
}

.head-dist-num {
  font-size: 1.5rem;
  font-weight: 550;
}

@media screen and (max-width: 1440px) {
  .head-dist-h {
    bottom: 40%;
  }
  .head-lions-i,
  .head-dist-num {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1440px) {
  .head-dist-h {
    bottom: 40%;
  }
  .head-lions-i,
  .head-dist-num {
    font-size: 3rem;
  }
}
@media screen and (max-width: 1024px) {
  .head-dist-h {
    bottom: 0% !important;
  }
  .head-lions-i,
  .head-dist-num {
    font-size: 2rem !important;
  }
}
@media screen and (max-width: 900px) {
  .head-dist-h {
    bottom: 5% !important;
  }

  .head-lions-i,
  .head-dist-num {
    font-size: 1.6rem !important;
  }
}
@media screen and (max-width: 600px) {
  .head-dist-h {
    bottom: 0% !important;
  }
  .head-lions-i,
  .head-dist-num {
    font-size: 1.3rem;
  }
  .imgSlide {
    margin-top: 5rem;
  }
}
@media screen and (max-width: 480px) {
  .head-dist-h {
    bottom: -27% !important;
  }

  .head-lions-i,
  .head-dist-num {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 280px) {
  .head-dist-h {
    bottom: -30% !important;
  }

  .head-lions-i,
  .head-dist-num {
    font-size: 0.75rem !important;
  }
}
