
footer {
    box-sizing: border-box;
    position: relative;
    margin: 0;
    background-color: #080a1a;
    color: #ffffff;
    font-style: normal;
    padding: 4px 0 10px;
    line-height: 24px;
    font-family: 'Tajawal', sans-serif;
    word-break: normal;
    /* border-radius: 10px; */
    box-shadow: 2px 6px 60px 20px rgba(0, 0, 0, 0.1);

}

a {
    text-decoration: none;
    color: white;
}

.row-footer {
    padding: 3px 20px 3px 20px;
}

.row-footer::after {
    content: "";
    clear: both;
    display: table;
}

@import url('https://fonts.googleapis.com/css2?family=Oregano&display=swap');

.site-logo {
    float: left;
    width: 25%;
    padding: 10px;
}

.site-logo span {
    font-family: 'Oregano', cursive;
    font-size: 20px;
}

.main-nav {
    position: absolute;
    top: 40%;
    left: 6%;
    margin: 0;
    padding: 0;
    width: 100px;
    height: -1px;
    box-shadow: 2px 2px 60px 8px rgba(102, 98, 98, 0.5);
}

.call {
    list-style: none;
}

.main-nav li {
    list-style: none;
    position: absolute;
    width: 30px;
    height: 30px;
    background: #000;
    transform: rotate(45deg);
    transition: 0.5s;
    margin: -30px;
    opacity: 0.5;
}

.main-nav li:hover {
    opacity: 1;
    
}

.main-nav li span {
    margin-left: 6.5px;
}

.main-nav li.item1 {
    top: 0;
    left: 0;
    color: #00a9c7;
}

.main-nav li.item2 {
    bottom: 0;
    left: 25%;
    color: red;
}

.main-nav li.item3 {
    top: 0;
    left: 50%;
    color: yellowgreen;
}

.main-nav li.item4 {
    bottom: 0;
    left: 75%;
    color: darkmagenta;
}

.site-links {
    float: left;
    width: 40%;
    padding: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');

.site-links span {
    font-family: 'Gruppo', cursive;
}

ul.list-links {
    list-style-type: none;
}

ul.list-links li {
    font-size: 14px;
    margin-left: -38px;
}


.location i {
    margin-right: 11px;
}


.follow-icons {
    display: flex;
    justify-content: center;
}

.follow-icons li {
    margin: 0 5px;
    list-style: none;
}

.follow-icons li i {
    font-size: 12px;
    font-weight: 0;
    color: #fff;
}

.follow-icons li span {
    padding-right: 6px;
}

.arrow {
    visibility: visible;
    padding-right: 15px;
    position: relative;
    animation: mymove 5s infinite;
}

@keyframes mymove {
    0% {
        left: 0px;
    }
    25% {
        left: 15px;
    }
    50% {
        left: 0px
    }
    75% {
        left: 15px;
    }
    100% {
        left: 0px;
    }
}

.right-dis {
    float: right;
}

.left-dis {
    float: left;
}

@media (max-width: 900px) {
    .subscribe-form {
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
    }

    .subscribe-form > p {
        margin-left: -5px;
    }

    .site-logo {
        float: left;
        width: 28%;
        padding: 10px;
    }

    .main-nav {
        top: 30%;
        left: 12%;
    }

    .site-links {
        float: left;
        width: 20%;
        padding: 10px;
    }

    .adr-email {
        width: 35%;
    }

    .adr-email .email {
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .right-dis {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    .left-dis {
        width: 100%;
        text-align: center;
    }

    .footer-logo{
        order: 1 !important;
    }
    .footer-nav01{
        order: 2;
    }
    .footer-nav02{
        order: 3;
    }
    

}


/*Footer Background Image*/

.footer-background {
    background-image: url('/public/assets/img/footer01.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    background-position-x: center;

}